@media screen and (min-width: 768px) {
	.program {
		padding: 0 $padding-tablet;
		min-width: 100%;
		p,
		&__more span {
			font-size: 1rem;
		}
		&__title {
			padding: .75rem $padding-tablet;
		}
		&__item {
			padding: 1.1875rem $padding-tablet;
			&:after {
				width: calc(100% - #{$padding-tablet});
				left: #{$padding-tablet/2};
			}
			p:first-child:before {
				width: 0;
				height: 0;
			}
			p.bold {
				letter-spacing: .5px;
			}
			&_hidden {
				opacity: 0;
				max-height: 0;
				padding: 0 $padding-tablet;
			}
		}
		&__time {
			display: flex;
			p {
				display: inline-block;
			}
			p:first-child {
				max-width: 160px;
				min-width: 120px;
			}
			p:not(:first-child) {
				margin-left: 65px;
				max-width: calc(100% - 230px);
				min-width: 370px;
				padding-top: 0;
			}
		}
		&_active {
			.program__item_hidden {
				padding: 1.4rem $padding-tablet;
			}			
		}
	}
}
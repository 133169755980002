.content {
	background: $white;
	color: $black;
	overflow: hidden;
	&__short-info,
	&__numbers,
	&__we-offer {
		margin-top: 80px;
	}
}

.content-po {
	padding: $padding-mobile;
	p {
		color: $black;
	}
	&__title {
		font-weight: 900;
		letter-spacing: .6px;
		font-size: 1.1rem;
		text-transform: uppercase;
	}	
	&__text {
		font-weight: 500;
	}
	p:not(:first-child) {
		margin-top: 25px;
	}
}

.short-info {
	&__heading,
	&__text,
	&__more {
		padding: 0 $padding-mobile;
	}
	&__heading {
		display: flex;
		align-items: center;
		div {
			border-radius: 2px;
			margin-right: 27px;
			display: inline-block;
			height: 7px;
			width: 54px;
			background: #4280D7;
		}
		span {
			color: #3E3E3E;
		}
	}
	&__img {
		display: flex;
		align-items: center;
		background: #F1F5F9;
		padding-left: 40px; 
		width: 80%;
		border-radius: 0 20px 20px 0;
		height: 184px;
		margin: 40px 0;
	}
	&__text {
		font-weight: 500;
		li {
			line-height: 1.4rem;
			letter-spacing: .6px;
			padding: .6rem 0;		
		}
	}
	&__text:not(:first-child) {
		padding-top: 1rem;
	}
	div.short-info__text {
		overflow: hidden;
		// padding-top: 0;
		transition: max-height .3s linear;
		max-height: 0;
		// opacity: 0;
	}
	div.short-info__text_active {
		// padding-top: 1rem;
		max-height: 500px;
		// opacity: 1;
	}
	
	&__more {
		margin-top: 23px;
		font-weight: 600;
		.arrow-to-right {
			transition: .3s ease-out;
		}
		.arrow-to-right_active {
			transform: rotate(180deg);
		}
	}
	&_main-themes {
		.short-info__img img {
			margin-top: -10px;
		}
		.short-info__heading {
			justify-content: flex-start;
			div {
				margin-right: 27px;
				width: 42px;
			}
		}
	}
}

.numbers {
	padding: 0 $padding-mobile;
	display: flex;
	flex-wrap: wrap;
	div {
		display: flex;
		span {
			color: $blue-font;
			font-size: 6.6rem;
			line-height: .8;
			padding-right: 1.5rem;
			font-weight: 800;
		}
		p {
			font-size: 0.83rem;
			max-width: 110px;
		}
	}
	div:not(:first-child) {
		margin-top: 3.5rem;
	}
}

.we-offer {
	padding: 3rem $padding-mobile;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	background: url(../images/svg/bg1.svg) center/cover no-repeat;
	background-size: 200% 100%;
	div img {
		border-radius: 10px;
		height: auto;
	}
	div {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	ul li {
		color: $white;
	}
}
@media screen and (min-width: 1280px) {
	.short-info {
		justify-content: space-between;
		&__img {
			justify-content: flex-end;
			picture {
				margin: 0;
				transform: scale(1);
				margin-right: $padding-tablet;
			}
		}
		&__more {
			cursor: pointer;
		}
		.h1 {
			font-size: 2.8125rem;
		}
		&__text {
			font-size: 1.125rem;
		}
	}
	.short-info_conference {
		.short-info__img {
			justify-content: flex-start;
		}
		.short-info__footer {
			max-width: 457px;
		}
	}
	.numbers {
		padding: 0px $padding-desktop;
		justify-content: center;
		div {
			padding: 70px 0;
			width: 400px;
			span {
				font-size: 8.75rem;
			}
			p {
				max-width: 100%;
				font-size: 1.1875rem;
			}
		}
	}
	.we-offer {
		padding: 0 $padding-desktop;
		div {
			max-width: 1200px;
			justify-content: space-between;
			img {
				transform: scale(1);
			}
		}
		div:first-child picture {
			margin-top: -60px;
		}
		div:last-child picture {
			margin-bottom: -60px;
		}
	}
}

@media screen and (min-width: 1366px) {
	.numbers {
		max-width: 1200px;
		margin: 80px auto;
		padding: 0;
		div:nth-child(1) {
			margin-left: -130px;
		}
		div:nth-child(2) {
			margin: 0 40px;
		}
		div:nth-child(3) {
			margin-right: -20px;
		}
	}
	.short-info {
		justify-content: center;
		position: relative;
		& > div {
			max-width: 600px;
		}
		& > div:nth-child(2):before {
			content: '';
			position: absolute;
			height: 100%;
			max-height: 302px;
			width: 50%;
			background: #F1F5F9;
			border-radius: 0 20px 20px 0;
			transform: translateX(-100px);
		}
		&__img {
			justify-content: flex-start;
			background: none;
			padding: 0;
			position: relative;
		}
		& > div:nth-child(2) {
			display: flex;
			justify-content: flex-end;
		}
		&__footer {
			max-width: 440px;
			float: right;
			margin-right: 67px;
		}
	}
	.short-info_conference {
		.short-info__img {
			justify-content: flex-end;
			picture {
				margin: 0;
			}
		}
		& > div:nth-child(2):before {
			content: '';
			position: absolute;
			height: 100%;
			max-height: 302px;
			width: 50%;
			background: #F1F5F9;
			border-radius: 20px 0 0 20px;
			transform: translateX(100px);
			right: 0;
		}
		& > div:nth-child(3) {
			justify-content: flex-start;
		}
		.short-info__footer {
			float: left;
		}
	}
}

@media screen and (min-width: 1650px) {
	.content-po {
		padding-left: calc(50% - 420px);
		padding-right: calc(50% - 420px);
	}
}

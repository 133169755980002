.header {
	padding: 0 $padding-mobile;
	display: flex;
	justify-content: space-between;
	height: 60px;
	width: 100%;
	&__left {
		display: flex;
		justify-content: flex-start;
	}
	&__logo {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: nowrap;
		div {
			opacity: .54;
			background: $white;
			height: 24px;
			width: 1px;
			margin: 0 1.25rem 0 .4rem;
		}
	}
	&__right {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		position: relative;
	}
	&__menu {
		display: none;
		li {
			padding: 20px 0;
			display: inline;
			cursor: pointer;
			position: relative;
		}
		li a {
			display: inline-block;
		}
		li:not(:first-child) {
			padding-left: 2rem;
		}
		.li-bottom {
			width: 97.73px;
			height: 5px;
			padding: 0;
			background: $white;
			margin-top: 5px;
			position: absolute;
			right: 0;
			bottom: 25px;
			transition: .3s .1s ease-out;
		}
	}
}
.hamburger {
	position: absolute;
	align-items: center;
	cursor: pointer;
	display: flex;
	flex-wrap: wrap;
	height: 23px;
	width: 22px;
	justify-content: space-between;
	z-index: 10;
	&__item {
		background: $white;
		display: block;
		height: 2px;
		width: 100%;
		transition: .3s ease-out;
	}
}
.hamburger_active {
	.hamburger__item {
		background: $dark;
		position: relative;
	}
	.hamburger__item:nth-child(2) {
		opacity: 0;
	}
	.hamburger__item:nth-child(1) {
		transform: rotate(45deg);
		transform-origin: center left;
	}
	.hamburger__item:nth-child(3) {
		transform: rotate(-45deg) translateX(6px) translateY(-15px);
		transform-origin: center right;
	}
	.hamburger__item_popup {
		transform: rotate(-45deg) translateX(6.7px) translateY(-15.6px) !important;
	}
}

.main-screen.main-screen-po {
	.menu-mobile {
		height: 100%;
	}
}

.menu-mobile {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	position: fixed;
	z-index: 9;
	overflow: hidden;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100vw;
	height: 100%;
	background: $white;
	color: $dark;
	visibility: hidden;
	transition: .3s ease-out;
	opacity: 0;
	&__header {
		display: flex;
		justify-content: center;
		background: url(../images/svg/mobile-menu1.svg) no-repeat;
		background-position: top left;
		align-items: center;
		height: 60px;
		span {
			padding-bottom: 5px;
			font-weight: 800;
		}
	}
	&__main {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		padding: 0;
		text-align: center;
		font-size: 1.42rem;
		line-height: 2.25rem;
		a {
			color: $superdark;
			letter-spacing: 0.38115px;
			font-weight: 600;
			display: inline-flex;
			text-align: right;
			width: auto;
		}
		a:not(:first-child) {
			margin-top: 2.75rem;
		}
		a:before,
		a:after {
			content: '';
			position: relative;
			height: 1.6px;
			width: 24px;
			background: $blue-menu;
			top: 1.12rem;
		}
		a:before {
			left: -22px;
		}
		a:after {
			left: 22px;
		}
	}
	&__footer {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		background: url(../images/svg/mobile-menu2.svg) no-repeat;
		background-position: bottom right;
		background-size: auto 201%;
		height: 100px;
		padding-bottom: $padding-mobile;
		a:nth-child(1) {
			margin-right: 50px; 
		}
	}
}
.menu-mobile_active {
	visibility: visible;
	opacity: 1;
}
@media screen and (max-width: 830px) and (max-height: 450px) and (orientation:landscape) {
	.numbers div {
		width: 100%;
		justify-content: center;
	}
	.we-offer img {
		min-width: 251px;
	}
	.short-info {
		&__img {
			display: flex;
			justify-content: flex-end;
			width: 60%;
			padding-right: 2.5rem;
		}
		&_main-themes {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			.short-info__img {
				display: flex;
				justify-content: flex-start;
				border-radius: 20px 0 0 20px;
				justify-content: flex-start;
				padding-left: 2.5rem;
			}
		}
	}
}
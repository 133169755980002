.program {
	margin-top: 80px;
	padding: 0 $padding-mobile;
	color: $black;
	height: auto;
	width: 100%;
	&__title {
		background: linear-gradient(180deg, #1C76E3 0%, #447BD6 100%);
		border-radius: 4px;
		display: flex;
		justify-content: space-between;
		padding: .75rem .875rem;
		align-items: center;
		color: $white;
		h2,
		p {
			letter-spacing: 0.05em;
			font-weight: 900;
		}
		h2 {
			font-size: 1.375rem;
		}
		p {
			font-size: .75rem;
		}
	}
	&__item {
		position: relative;
		padding: 1.1875rem $padding-mobile;
		display: flex;
		justify-content: space-between;
		align-items: center;
		transition: .3s ease-out;
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 100%;
			width: 100%;
			height: 3px;
			background: rgba(66, 128, 215, 0.35);
			border-radius: 2px;
		}
		p {
			position: relative;
			font-size: .9rem;
		}
		p.light {
			font-weight: 500;
		}
		p.bold {
			font-weight: 800;
			letter-spacing: .3px;
			max-width: 280px;
		}
		p:not(:first-child) {
			padding-top: .75rem;
		}
		p:first-child:before {
			content: '';
			position: absolute;
			left: -18px;
			top: calc(50% - 1.6px);
			width: 12px;
			height: 3.2px;
			background: url(../images/svg/icon-line.svg) center/cover no-repeat;
		}
		&_hidden {
			opacity: 0;
			max-height: 0;
			padding: 0 1.125rem;
		}
	}
	&__more {
		padding: 1.1875rem 1.125rem 0 1.125rem;
		text-align: center;
		cursor: pointer;
		span {
			font-size: .8125rem;
			position: relative;
		}
		span:before,
		span:after {
			content: '';
			position: absolute;
			top: calc(50% - 8px);
			width: 13px;
			height: 16px;
			background: url(../images/svg/m-arrow-top-blue.svg) center/cover no-repeat;
			transform: rotate(180deg);
			transition: .3s ease-out;			
		}
		span:before {
			left: -22px;
		}
		span:after {
			right: -22px;
		}
	}
	&_active {
		.program__item_hidden {
			opacity: 1;
			max-height: 800px;
			padding: 1.1875rem 1.125rem;
		}
		.program__more {
			span:before,
			span:after {
				transform: rotate(0deg);	
			}			
		}
	}
}
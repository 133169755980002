// Album orientation

@import "orientation/album/desktop__header";

@import "orientation/album/desktop__main-screen";

@import "orientation/album/desktop__content";

@import "orientation/album/desktop__program";

@import "orientation/album/desktop__footer";

@import "orientation/album/desktop__popup";


@media screen and (min-width: 1280px) {
  .desktop {
    display: block !important;
  }
}
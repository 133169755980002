@media screen and (min-width: 1280px) {
	.program {
		max-width: 1200px;
		margin: 80px auto;
	}
}

@media screen and (min-width: 1366px) {
	.program {
		width: 1200px;
		min-width: 1200px;
		padding: 0;
		&__time {
			p:not(:first-child) {
				max-width: calc(100% - 180px)
			}
		} 
		span.program__time {
			p {
				max-width: 100%;
			}
		}
	}
}
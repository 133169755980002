.main-screen {
	position: relative;
	height: 100vh;
	max-height: 100%;
	width: 100%;
	background: linear-gradient(103.1deg, #1C76E3 -321.82%, #653FCB 262.75%);
	display: flex;
	flex-wrap: wrap;
	padding: 0 0;
	&__heading {
		background: url(../images/svg/people1.svg) center no-repeat;
		background-size: 140% auto;
		background-position: center 15vh;
		display: flex;
		flex-wrap: wrap;
		height: calc(100% - 60px - 66px);
		padding: 25px 0;
		& > div {
			width: 100%;
			padding: 0 $padding-mobile;
			a {
				display: inline-block;
			}
		}
		& > div:last-child {
			display: flex;
			align-items: flex-end;
		}
		.h1 {
			font-size: 1.5rem;
			margin-top: 13px;
			text-transform: uppercase;
			letter-spacing: 2px;
		}
	}
	&__button {
		margin: 0 auto 30px;
		.arrow-to-right {
			margin-top: 3px;
			margin-left: 20px;
		}
	}
	&__footer {
		position: relative;
		height: auto;
		width: 100%;
		display: flex !important;
		padding-bottom: 40px;
		& > div {
			padding: 0 $padding-desktop;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: nowrap;
			align-items: flex-end;
			transform: translateY(-175px)
		}
		div div:nth-of-type(1) a:not(:first-child) {
			margin-left: 53px;
		}
		div div:nth-of-type(2) {
			margin-top: 0px;
			transform: translateY(-4px);
		}
		div div:nth-of-type(2) a {
			display: block;
			width: auto;
			height: 55px;
			padding-left: -50px;
			transform: rotate(90deg) translateY(-10px);
			cursor: pointer;
		}
		.draggable p {
			font-weight: 900;
			letter-spacing: 0.888889px;
		}
		.draggable img {
			transform: rotate(-90deg);
			margin-left: 25px;
			margin-top: -10px;
		}
		a {
			cursor: pointer;
		}
		.draggable:hover img {
			animation: drag 1s infinite;
		}
	}
}

.main-screen-po {
	max-height: 250px;
	display: flex;
	justify-content: center;
	background: linear-gradient(156.11deg, #1CC5E3 -87.18%, #653FCB 142.88%);
	&__title {
		font-size: 1.5rem;
		text-transform: uppercase;
		letter-spacing: 1.2px;
	}
}

@keyframes drag {
  0%   { transform: rotate(270deg) translateY(0px); }
  50%  { transform: rotate(270deg) translateY(-15px); }
  100% { transform: rotate(270deg) translateY(0px); }
}
@media screen and (min-width: 768px) {
	.main-screen {
		background: linear-gradient(151.3deg, #1CC5E3 -87.18%, #653FCB 142.88%);
		&__heading {
			background: url(../images/svg/people1.svg) center no-repeat;
			background-size: 100% auto;
			background-position: center 10vh;
			padding: 70px 0;
			height: calc(100% - 100px - 85px);
			& > div {
				padding: 0 $padding-tablet;
				p {
					font-size: 1.375rem;
					span {
						font-size: 1.6875rem;
					}
				}
				.h1 {
					font-size: 2.3rem;
				}
			}
		}
		&__button {
			margin: 0 auto 40px;
			span {
				font-size: 1.1875rem;
			}
		}
	}
	.main-screen-po {
		min-height: 300px;
		&__title {
			font-size: 3rem;
		}
	}
}
// Portarait orientation

@import "orientation/portrait/mobile__header";

@import "orientation/portrait/mobile__content";

@import "orientation/portrait/mobile__footer";



// Album orientation

@import "orientation/album/mobile__header";

@import "orientation/album/mobile__main-screen";

@import "orientation/album/mobile__content";

@import "orientation/album/mobile__footer";



@media only screen and (min-width: 350px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 400px) {
  html {
    font-size: 16px;
  }
}

// for landscape mobile

@media only screen and (max-width: 700px) and (min-aspect-ratio: 8/5) {
  html {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
	.tablet,
	.desktop {
		display: none !important;
	}
}

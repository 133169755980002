@media screen and (min-width: 1280px) {
	.popup {
		z-index: 999;
		width: 980px;
		padding: 40px $padding-desktop 40px $padding-desktop;
		height: auto;
		border-radius: 20px;
		top: 50%;
		left: 50%;
		transform: translate(-49.54%, -49.1%);
		perspective: 1000;
		&__header {
			display: none;
		}
		.desktop {
			position: absolute;
			right: $padding-desktop;
			top: 47px;
			cursor: pointer;
		}
	}
	.popup-wrapper {
		position: fixed;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.4);
		left: 0;
		top: 0;
		z-index: -10;
		opacity: 0;
		visibility: hidden;
		transition: .3s ease-out;
		&_active {
			z-index: 998;
			opacity: 1;
			visibility: visible;
		}
	}
	.popup-small {
		padding: 5rem $padding-desktop;
		width: 665px;
		h1 {
			font-size: 2.2rem
		}
		p {
			font-size: 1.25rem;
		}
	}
	@-webkit-keyframes bounceIn {
	  0% {
	    opacity: 0;
	    -webkit-transform: scale(.3);
	  }

	  50% {
	    opacity: 1;
	    -webkit-transform: scale(1.05);
	  }

	  70% {
	    -webkit-transform: scale(.9);
	  }

	  100% {
	    -webkit-transform: scale(1);
	  }
	}
}
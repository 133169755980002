@media screen and (min-width: 768px) and (orientation: landscape) {
	.footer {
		&__contact-us {
			h1 {
				font-size: 5.7rem;
			}
			.contact-us__item {
				width: auto;
				justify-content: space-between;
				h2 {
					font-size: 2.2rem;
				}
			}
		}
		.desktop-visa {
			display: flex !important;
		}
		.mobile-visa {
			display: none !important;
		}
	}
}
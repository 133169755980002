@media screen and (min-width: 360px) {
	.numbers div span {
		font-size: 5.6rem;
	}
}

@media screen and (min-width: 385px) {
	.we-offer {
		picture {
			transform-origin: bottom center;
			transform: scale(1.1);	
		}
		img.mobile {
			transform-origin: top center;
			transform: scale(1.1);		
		}
	}
}


@media screen and (min-width: 400px) {
	.we-offer {
		picture {
			transform-origin: bottom center;
			transform: scale(1.15);	
		}
		img.mobile {
			transform-origin: top center;
			transform: scale(1.15);		
		}
	}
}
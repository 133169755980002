.button {
	background: $white;
	color: $blue-font;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 1.46rem;
	font-weight: 700;
	border: 0;
	height: auto;
	padding: .83rem 1.91rem;
	letter-spacing: 0.611429px;
	cursor: pointer;
}
.button:focus,
.quantumWizButtonEl:focus {
	outline: 0;
}

.button_blue {
	background: $blue-font;
	color: $white;
	padding: .83rem 2.75rem;
}
a.button {
	display: inline-block;
	width: auto;
}
@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";

html {
  color: $white;
  font-family: $base-font;
  font-size: $base-size;
  line-height: 1;
}

body { height: 100%; }

h1, h2, h3, h4, h5, h6 { margin: 0; font-weight: 700; line-height: 1.3;}

h1,
.h1 {
  font-size: 1.83rem;
}

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a { color: $white;
    text-decoration: none;  
  &:focus,
  &:active,
  &:visited {
    color: $white;
  }
  span {
    color: $blue-font
  }
}

p { 
  font-family: $base-font;
  margin: 0;
  line-height: 1.5;
}

audio,
canvas,
iframe,
img,
svg,
video,
textarea { vertical-align: middle; }

textarea { resize: none; }

section { position: relative; }

input,
select,
button { outline: none; appearance: none; height: 3rem; }

*, *:before, *:after { box-sizing: border-box; }

.wrapper {
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content { flex: 1 0 auto; }

.container {
  margin: 0 auto;
  max-width: $content-size;
}
.content-wrapper {
  width: 100%;
  max-width: 1170px; 
}
.low-opacity {
  opacity: .8;
}
.semi-bold {
   font-weight: 600;
}
.bold {
  font-weight: 700;
}
.extra {
 font-weight: 900;
}
.italic {
  font-style: italic;
}
.arrow-to-right {
  margin-left: 13px;
}
.hide {
  overflow: hidden;
  height: 100%;
}
.low-hide {
  overflow: hidden;
  position: fixed;
  height: auto;
}
#hidden_iframe {
  display: none;
}

ul {
  padding: .8rem 0;
  li {
    padding: .6rem 0;
    line-height: 1.4rem;
    letter-spacing: .6px;
    font-size: 1rem;
  }
}



//Modules
@import "modules/modules";

//Libraries
@import "libs/libs";

//Mediascreen
@import "mediascreen/mediascreen";
@media screen and (min-width: 1280px) {
	.main-screen {
		overflow: hidden;
		&__heading {
			height: calc(100% - 100px);
			padding: 0 0 100px 0;
			flex-wrap: nowrap;
			width: 100%;
			background: none;
			overflow: hidden;
			z-index: 2;
			justify-content: center;
			& > div {
				padding: 0;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				width: 50%;
				div {
					max-width: 512px;
				}
			}
			& > div:first-of-type {
				padding-left: $padding-desktop;
				justify-content: flex-start;
				p:first-child {
					font-size: 1.125rem;
				}
				.h1 {
					font-size: 2.1875rem;
					margin-top: 38px;
				}
				p.desktop {
					font-size: 1.125rem;
					margin-top: 38px;
					span {
						font-size: 1.3rem;
					}
				}
				a {
					float: left;
					margin-bottom: 0;
					margin-top: 38px;
					padding: 14px 33px;
					letter-spacing: 1px;
				}
				.arrow-to-right {
					margin-top: -3px;
				}
			}
			& > div:last-child {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				padding-right: $padding-desktop;
				p {
					display: none;
				}
			}
		}
		&__button {
			display: none;
		}
		&__footer {
			z-index: 2;
		}
		&__footer-img {
		    position: absolute;
		    top: 36vh;
		    left: -1vw;
		    width: 110vw;
		    height: 83vh;
		    z-index: 1;
		}
	}
	.main-screen-po {
		min-height: 400px;
		&__title {
			font-size: 4.55rem;
		}
	}
}


@media screen and (min-width: 1366px) {
	.main-screen {
		&__heading {
			& > div {
				div {
					max-width: 600px;
				}
			}
			div:first-child {
				justify-content: flex-end;
			}
			div:last-child {
				justify-content: flex-start;
				img {
					transform-origin: left center;
				}
			}
		}
	}
}

@media screen and (max-width: 1100px) and (max-height: 600px) {
	.main-screen__heading {
		padding: 25px 0;
	}
}

// @media screen and (min-width: 1440px) {

// }

// @media screen and (min-width: 1680px) {
 
// }

// @media screen and (min-width: 1920px) {
 
// }
.footer {
	margin-top: 80px;
	background: linear-gradient(352.6deg, #1CC5E3 -1771.1%, #653FCB 1901.49%);
	color: $white;
	padding: 3.5rem 0;
	&__list {
		padding: 1.2rem 0;
	}
	h1 {
		letter-spacing: 0.67px;
	}
	&__registration {
		text-transform: uppercase;
		font-size: 1.16rem;
		cursor: pointer;
	}
	&__item {
		padding: 0 $padding-mobile;
	}
	&__item:not(:first-child) {
		margin-top: 4.2rem;
	}
	&__partners {
		margin-top: 4.2rem;
		padding: 3.625rem $padding-mobile;
	}
	&__contact-us {
		padding: 3.625rem $padding-mobile;
		h1 {
			letter-spacing: 0;
		}
	}
	&__creators {
		padding: 3.5rem $padding-mobile 0 $padding-mobile;
		font-size: 1.33rem;
		p, span {
			line-height: 1.83rem;
		}
		p {
			width: 100%;
		}
		a {
			font-weight: 500;
			display: inline-block;
		}
		& > div:nth-child(3) {
			display: flex;
			justify-content: center;
		}
		& > div:nth-child(2) {
			margin-bottom: 60px;
		}
		.public-offer {
			margin-top: 30px;
			display: flex;
			justify-content: center;
			a {
				text-align: center;
				letter-spacing: 0.4992px;
				font-size: 1.375rem;
				text-decoration: underline;
				cursor: pointer;
			}
		}		
	}
	&__site {
		margin-bottom: 60px;
		text-align: center;
	}
	&__design {
		margin-bottom: 26px;
	}
	&__design,
	&__development {
		min-width: 300px;
	}
	.desktop-visa {
		display: none !important;
	}
}

.footer-po {
	min-height: 250px;
	background: linear-gradient(155.1deg, #1CC5E3 -87.18%, #653FCB 142.88%);
	display: flex;
	justify-content: center;
	align-items: center;
	&__go-back {
		text-transform: uppercase;
		text-decoration: underline;
		font-weight: 900;
		letter-spacing: .6px;
		font-size: 1.5rem;
	}
}


.partners,
.contact-us {
	background: $white;
	color: $superdark;
}

.partners {
	h1 {
		font-size: 1.7rem;
		font-weight: 800;
	}
	p {
		text-decoration: underline;
		font-weight: 600;
		font-size: 1.16rem;
		padding-top: .5rem;
		padding-bottom: 2.91rem;
		cursor: pointer;
	}
	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		div {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		div:not(:first-child) {
			padding-top: 2.5rem;
			span {
				width: 70px;
			}
		}
	}
}

.contact-us {
	h1 {
		font-size: 3.125rem;
		color: #447DD7;
		opacity: .45;
		font-weight: 900;
		img {
			padding-left: 1rem;
		}
	}
	a {
		color: $superdark;
	}
	&__item {
		padding: $padding-mobile 0;
		p {
			font-size: 1.08rem;
		}
		h2 {
			font-size: 1.83rem;
			font-weight: 800;
			text-transform: uppercase;
		}
		div {
			margin-top: 1rem;
			font-size: 1.08rem;
			font-weight: 600;
			display: flex;
			justify-content: space-between; 
		}
	}
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
	.program {
		padding: 0 $padding-desktop;
		&__title {
			padding: .75rem $padding-desktop;
			h2 {
				font-size: 2.5rem;
				font-weight: 800;
			}
			p {
				font-size: 1.25rem;
			}
		}
		&__item {
			padding: 1.1875rem $padding-desktop;
			&:after {
				width: calc(100% - #{$padding-desktop});
				left: #{$padding-desktop/2};
			}
			&_hidden {
				padding: 0 $padding-desktop;
			}
		}
		&__time {
			p {
				font-size: 1.25rem;
			}
			p:first-child {
				min-width: 150px;
			}
		}
		&_active {
			.program__item_hidden {
				padding: 1.4rem $padding-desktop;
			}			
		}
	}
}
@media screen and (min-width: 1280px) {
	.header {
		padding: 0 $padding-desktop;
		&__menu {
			display: block;
		}
	}
	.hamburger {
		display: none;
	}
}

@media screen and (max-width: 1100px) and (max-height: 600px) {
	.menu-mobile {
		height: auto;
	}
}
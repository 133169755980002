@media screen and (min-width: 1024px) and (orientation: landscape) {
	.numbers {
		justify-content: center;
		div {
			padding: 35px 0;
			justify-content: flex-start;
			width: 250px;
			& > * {
				width: 100%;
				padding-left: 25%;
			}
			span {
				width: 100%;
				text-align: left;
			}
			p {
				width: 100%;
				max-width: 220px;
				text-align: left;
			}
		}
	}
	.we-offer {
		position: relative;
		div {
			justify-content: center;
			height: 293px;
			align-items: center;
			& > * {
				width: 50%;
			}
			ul {
				padding: 0;
				padding-bottom: 0 !important;
				margin-top: 0 !important;
				align-self: center;
			}
			img {
				transform: scale(1.2);
			}
		}
		div:first-child {
			width: 100%;
			margin-bottom: 0;
			margin-top: 13px;
			ul {
				width: 50%;
				margin-right: 100px;
			}
			picture {
				margin-left: 100px;
			}
			img {
				transform-origin: top left !important;
			}
		}
		div:last-child {
			margin-top: 0;
			ul {
				width: 50%;
				margin-left: 100px;
			}
			picture {
				margin-right: 100px;
				transform-origin: bottom right;
			}
			img {
				transform-origin: bottom right !important;
			}
		}
		div picture {
			transform: scale(1);
		}
		div:first-child img {
			align-self: flex-start !important;
			transform-origin: bottom left;
		}
		div:last-child img {
			align-self: flex-end !important;
			transform-origin: top left;
			float: right;
		}
	}
	.short-info_conference {
		margin-top: 100px;
		.short-info__footer {
			max-width: 400px;
		}
		.short-info__img {
			justify-content: flex-start;
		}
	}
	.short-info {
		margin-top: 150px;
		&__img {
			padding: 0 2rem;
			img {
				overflow: visible;
				height: 320px;
			}
			picture {
				transform: scale(1);
				margin-left: -.8rem;
			}
		}
	}
	.short-info_main-themes {
		overflow: visible;
		padding-bottom: 30px;
	}
}
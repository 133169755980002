.popup {
	z-index: 10;
	padding: 0 $padding-mobile $padding-mobile $padding-mobile;
	max-height: 100vh;
	overflow-y: auto;
	height: 100%;
	position: fixed;
	width: 100%;
	color: $superdark;
	background: $white;
	visibility: hidden;
	opacity: 0;
	transition: .3s ease-out;
	a {
		color: $black;
	}
	a.button {
		color: $white;
	}
	&__header {
		height: 60px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	&__title {
		font-size: 1.42rem;
		font-weight: 900;
		line-height: 1.22;
		letter-spacing: 0.294525px;
	}
	.freebirdFormviewerViewHeaderDescription {
		p {
			padding-top: .83rem;
			font-weight: 500;
			font-size: .92rem;
			letter-spacing: 0.311667px;
			line-height: 1.55;
		}
		p:last-of-type {
			padding-bottom: 3.33rem;
		}
	}
	.freebirdFormviewerViewItemsItemItemTitle {
		font-weight: 700;
		font-size: 1rem;
		line-height: 1.25;		
	}
	input.quantumWizTextinputPaperinputInput,
	textarea.quantumWizTextinputPapertextareaInput {
		width: 60%;
		min-width: 240px;
		border: 0;
		border-bottom: 1px solid #A0A0A0;
		color: $superdark;
		height: 1.66rem;
		margin-top: .5rem;
		margin-bottom: 1.3rem;
		&::placeholder { color: #DADADA; }
	}
	.quantumWizButtonPaperbuttonLabel {
		margin-top: 1.83rem;
		display: inline-block;
		outline: none;
		width: auto;
	}
	&__item {
		margin-top: 1.4rem;
		span {
			font-weight: 500;
			font-size: .83rem;
			line-height: 1.5;
			letter-spacing: 0.34px;
			display: block;
			color: $black;
			padding-top: .5rem;
			max-width: 150px;
		}
		p {
			font-weight: 700;
		}
	}
}

.popup_active {
	visibility: visible;
	opacity: 1;
}

#popup_success .popup-small {
	box-shadow: 0px 1px 44px rgba(20, 255, 0, 0.25);
	text-align: center;
}
.popup-container {
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.4);
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: hidden;
	opacity: 0;
	transition: .3s ease-out;
}
.popup-container-partner_active,
.popup-container-success_active {
	visibility: visible;
	opacity: 1;
	z-index: 12;
}
.popup-small {
	position: relative;
	width: 100%;
	height: auto;
	background: $white;
	color: $superdark;
	padding: 5rem $padding-mobile;
	border-radius: 20px;
	box-shadow: 0px 1px 44px rgba(64, 132, 216, 0.34);
	z-index: 13;
	visibility: visible;
	opacity: 1;
	&__hamburger {
		position: absolute;
		top: 28px;
		right: 28px;
		cursor: pointer;
	}
	h1 {
		font-size: 1.5rem;
		font-weight: 900;
		letter-spacing: 1.61px;
	}
	p {
		font-weight: 700;
		font-size: 1.08rem;
		letter-spacing: 0.606375px;
		padding-top: 1rem;
	}
	p:nth-of-type(1) {
		padding-top: 2rem;
	}
	a {
		color: $superdark;
	}
}
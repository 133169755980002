@media screen and (min-width: 1024px) and (orientation: landscape) {
	.main-screen {
		&__heading {
			background-size: auto 130%;
			background-position: center -5vh;
			& > div:nth-child(2) img {
				width: 600px;
				height: auto;
			}
		} 
	}
}
@media screen and (min-width: 768px) {
	.popup {
		padding: 0 $padding-tablet $padding-tablet $padding-tablet;
		&__header {
			height: 100px;
		}
		.hamburger_active .hamburger__item_popup {
    		transform: rotate(-45deg) translateX(6.7px) translateY(-15.6px) !important;
		}
		.freebirdFormviewerViewHeaderTitle {
			font-size: 2.2rem;
			padding-bottom: 1rem;
		}
		.freebirdFormviewerViewHeaderDescription {
			p {
				font-size: 1.125rem;
			}
			p:last-of-type {
				padding-bottom: 3.33rem;
			}
		}
		.freebirdFormviewerViewItemsItemItemTitle {
			font-size: 1.25rem;
		}
		.quantumWizTextinputPaperinputInput {
			font-size: 1.125rem;
		}
		.quantumWizButtonPaperbuttonLabel {
			margin-top: 2.83rem;
		}
		&__item {
			p {
				font-size: 1.25rem;
			}
			span {
				padding-top: .5rem;
				font-size: 1rem;
				max-width: 100%;
			}
		}
		button {
			font-size: 1.1875rem;
		}
	}
	.popup-small {
		width: 70%;
	}
}
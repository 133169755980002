@media screen and (min-width: 768px) {
	.header {
		padding: 0 $padding-tablet;
		height: 100px;
		&__logo {
			div {
				height: 32px;
			}
		}
	}
	.menu-mobile {
		&__header {
			height: 100px;
			h1 {
				padding-bottom: 0;
			}
		}
		&__footer {
			padding-bottom: $padding-tablet;
		}
	}
}
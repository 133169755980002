// Portarait orientation

@import "orientation/portrait/tablet__header";

@import "orientation/portrait/tablet__main-screen";

@import "orientation/portrait/tablet__content";

@import "orientation/portrait/tablet__program";

@import "orientation/portrait/tablet__popup";

@import "orientation/portrait/tablet__footer";


// Album orientation

@import "orientation/album/tablet__header";

@import "orientation/album/tablet__main-screen";

@import "orientation/album/tablet__content";

@import "orientation/album/tablet__program";

@import "orientation/album/tablet__popup";

@import "orientation/album/tablet__footer";

@media screen and (min-width: 768px) {
	.mobile,
	.desktop {
		display: none !important;
	}
	.tablet {
		display: block;
	}
	.arrow-to-right {
		margin-left: 30px;
	}
}
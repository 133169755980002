@media screen and (min-width: 768px) {
	.short-info {
		display: flex;
		justify-content: space-between;
		overflow: visible;
		&__img {
			display: flex;
			justify-content: flex-end;
			margin: 0;
			width: 100%;
			height: 300px;
			picture {
				margin-right: 0;
				transform: scale(.7);
			}
		}
		&__text {
			font-size: 1.125rem;
		}
		&__more {
			margin-top: 0;
			font-size: 1.125rem;
		}
		& > * {
			width: calc(50% - 1.6rem);
		}
		&__footer > * {
			padding-left: 0;
			max-width: 430px;
		}
		&__footer {
			position: relative;
			height: 100%;
		}
		&__footer:after {
			content: '';
			position: absolute;
			width: 70px;
			height: 10px;
			border-radius: 2px;
			background: $blue;
			bottom: 0;
			left: 0;
			transition: .3s ease-out;
		}
	}
	.short-info__footer_active:after {
		left: 2000px;
	}
	.short-info_conference {
		.short-info__img {
			border-radius: 20px 0 0 20px; 
		}
		& > div:nth-child(2) {
			order: 1;
		}
		& > div:nth-child(3) {
			display: flex;
			justify-content: flex-end;
		}
		.short-info__footer {
			padding-left: 0;
			padding-right: 0;
			max-width: 300px;
			float: none;
			p {
				padding-right: 0;
			}
		}
		.short-info__footer:after {
			left: 0;
		}
	}
	.numbers {
		flex-wrap: nowrap;
		justify-content: space-around;
		padding: 0 $padding-tablet;
		div {
			padding: 55px 10px;
			flex-wrap: wrap;
			background: url(../images/general/numbers-shape.png) center/cover no-repeat;
			background-size: 100% 100%;
			justify-content: center;
			span {
				width: 150px;
				font-size: 5rem;
				line-height: 1.3;
				padding-right: 0;
			}
			p {
				max-width: 150px;
				text-align: left;
			}
		}
		div:not(:first-child) {
			margin-top: 0;
		}
	}
	.we-offer {
		margin-top: 110px; 
		padding: 0 $padding-tablet;
		background: url(../images/svg/bg1-wide.svg) center no-repeat;
		max-height: 600px;
		position: relative;
		div {
			flex-wrap: nowrap;
			justify-content: space-between;
			height: auto;
		}
		div ul {
			width: 45%;
			padding: 2rem 0;
		}
		div:first-child picture,
		div:last-child ul {
			order: 1;
		}
		// div:last-child {
		// 	margin-top: 50px;
		// }
		div picture {
			min-width: auto;
			transform: scale(1.3);
		}
		div:first-child picture {
			align-self: flex-start;
			margin-top: -30px;
			transform-origin: top right;
		}
		div:last-child picture {
			align-self: flex-end;
			margin-bottom: -30px;
			transform-origin: bottom left;
		}
		div:last-child ul {
			margin-top: -30px;
			padding-top: 0;
			padding-bottom: 30px;
		}
		div:first-child {
			align-items: flex-end;
			margin-bottom: 40px;
		}
		div:last-child {
			margin-top: 40px;
			align-items: flex-start;
		}
	}
	.content-po {
		padding: $padding-tablet;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		p {
			font-size: 1.125rem;
			max-width: 800px;
			width: 800px;
			text-align: left;
		}
	}
}
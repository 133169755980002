// Colors
$black: #3E3E3E;
$white: #fff;
$dark: #7C7C7C;
$superdark: #3E3E3E;
$blue-menu: #AACDFC;
$purple: #653FCB;
$aqua: #1CC5E3;
$blue: #4E69D3;
$blue-font: #3F7ECE;
$lightblue: #3E88D8;
$blue-bottom: #4D6CD3;

// Fonts
$base-font: 'Montserrat', sans-serif;

// Paddiings
$padding-mobile: 1.66rem;
$padding-tablet: 2.5rem;
$padding-desktop: 3.75rem;

//Sizes
$base-size: 12px;
$content-size: 100%;
@media screen and (min-width: 1280px) {
	.footer {
		background: $white;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		&__top {
			width: 100%;
			background: linear-gradient(165.79deg, #1CC5E3 -185.95%, #653FCB 235.97%);
		}
		&__item {
			width: 100%;
			padding: 3.5rem $padding-desktop;
			.footer__container:nth-of-type(1) {
				padding-left: 0;
			}
			.footer__container:nth-of-type(2) {
				padding-right: 0;
			}
		}
		&__container {
			padding: 0 $padding-desktop;
		}
		&__creators {
			width: 100%;
			padding: 5rem $padding-desktop 2.25rem $padding-desktop;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			& > div {
				width: 100%;
			}
		}
		&__contact-us {
			min-width: 100%;
		}
		&__partners {
			padding: 5rem $padding-desktop;
			justify-content: space-between;
			.partners__wrapper {
				width: calc(100% - 200px);
				div {
					display: flex;
					flex-flow: row wrap;
					height: auto;
					justify-content: flex-start;
					margin-bottom: 60px;
					picture {
						margin-top: 60px;
						width: 175px;
						display: flex;
						justify-content: center;
					}
				}
			}
		}
		&__contact-us {
			padding: 5rem $padding-desktop;
			h1 {
				font-size: 7rem;
			}
		}
	}
}


@media screen and (min-width: 1366px) {
	.footer {
		&__item {
			width: 1200px;
			padding: 3.5rem 0;
			margin: 0 auto;
			justify-content: space-between;
		}
		&__container {
			width: 500px;
			padding: 0;
		}
		&__creators {
			& > div {
				max-width: 1200px;
			}
		}
		&__partners,
		&__contact-us {
			padding: 5rem 0;
			max-width: 1200px;
			min-width: 1200px;
		}
		&__partners .partners__wrapper div picture {
			width: 189px;
		}
		&__partners .partners__wrapper div .last-deskt {
			display: flex;
			justify-content: flex-end;
		}
		&__contact-us {
			h1 {
				font-size: 7.359rem;
			}
		}
	}
}
@media screen and (min-width: 768px) {
	.footer {
		padding: 0;
		&__item {
			padding: 3.5rem 0;
			display: flex;
			h1 {
				font-size: 2.2rem;
			}
		}
		&__item:not(:first-child) {
			border-top: 5px solid #4F86D9;
			margin-top: 0;
			padding-top: 3.5rem;
		}
		&__container {
			width: 50%;
			padding: 0 $padding-tablet;
			display: flex;
			justify-content: flex-start;
			.tablet {
				display: flex;
			}
			.footer__registration {
				margin-top: 1.8rem;
			}
		}
		&__list {
			padding: 0;
			li:first-child {
				padding-top: 0;
			}
		}
		&__registration {
			display: flex;
			text-transform: none;
		}

		&__creators {
			background: linear-gradient(174.35deg, #1CC5E3 -185.95%, #653FCB 235.97%);
			padding: 5rem $padding-tablet 2.25rem $padding-tablet;
			p span {
				padding-top: .6rem;
			}
		}
		&__creators div:nth-child(1) {
			text-align: center;
		}
		&__creators div:nth-child(2) {
			display: flex;
			justify-content: space-between;
			p {
				display: inline;
				width: auto;
			}
		}
		&__design {
			margin-bottom: 0;
		}
		&__partners {
			padding: 3.625rem $padding-tablet;
			display: flex;
			& > * {
				display: block;
			}
			h1 {
				font-size: 2.8rem;
			}
			p {
				font-size: 1.25rem;
			}
			span {
				width: 350px;
			}
		}
		.partners__wrapper {
			width: calc(100% - 350px);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			div {
				display: flex;
				flex-flow: row wrap;
				height: auto;
				picture {
					padding: 20px 0;
					width: 160px;
					display: flex;
					justify-content: center;
				}
				picture:not(:first-child):not(:nth-child(2)) {
					margin-top: 40px;
				}
			}
		}
		&__contact-us {
			padding: 3.625rem $padding-tablet;
			h1 {
				font-size: 4.15rem;
				img {
					display: none;
				}
			}
			& > div {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
			.contact-us__item {
				text-align: left;
				width: 50%;
				justify-content: center;
				div,
				div span {
					display: block;
				}
				div span {
					padding-bottom: 10px;
				}
				p {
					font-size: 1rem;
				}
				h2 {
					font-size: 2rem;
				}
				div span,
				div a {
					font-size: 1.25rem;
				}
			}
		}
	}
}
@media screen and (min-width: 1024px) {
	.footer {
		.desktop-visa {
			display: flex !important;
		}
		.mobile-visa {
			display: none !important;
		}
	}
}